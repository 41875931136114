import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export const useScrollRestoration = () => {
  const router = useRouter();
  const previousUrl = useRef(router.asPath);

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';

      const saveScrollPosition = () => {
        sessionStorage.setItem(
          `scrollPosition-${previousUrl.current}`,
          JSON.stringify({ x: window.scrollX, y: window.scrollY }),
        );
      };

      const restoreScrollPosition = () => {
        const scrollPosition = sessionStorage.getItem(
          `scrollPosition-${router.asPath}`,
        );
        if (scrollPosition) {
          const { x, y } = JSON.parse(scrollPosition);
          setTimeout(() => {
            window.scrollTo(x, y);
          }, 100);
        }
      };

      router.events.on('routeChangeStart', saveScrollPosition);
      router.events.on('routeChangeComplete', restoreScrollPosition);

      return () => {
        router.events.off('routeChangeStart', saveScrollPosition);
        router.events.off('routeChangeComplete', restoreScrollPosition);
      };
    }
  }, [router]);

  useEffect(() => {
    previousUrl.current = router.asPath;
  }, [router.asPath]);
};
